export const Actions = {
  SHOW_MODAL: "SHOW_MODAL",
  SET_FOOTER_CONTENT: "SET_FOOTER_CONTENT",
  SHOW_MOBILE_NAVIGATION: "SHOW_MOBILE_NAVIGATION",
  ADAPT_NAV_LINKS: "ADAPT_NAV_LINKS",
};

export const FOOTER_NAV_ITEM = {
  IMPRINT: "Impressum",
  DATA_PRIVACY: "Datenschutz",
};

export const MEDIA_PATH = '';

export const initialState = {
  showModal: false,
  currentFooterContent: {
    headline: null,
  },
  navigation: {
    navLinks: ["Partner", "Produkte", "Referenzen", "Unternehmen", "Kontakt"],
    navFooterLinks: ["Datenschutz", "Impressum"],
    callToAction: {
      title: "Fernwartung",
      url: `${MEDIA_PATH}/application/TeamViewerQS.exe`,
    },
    showMobileNavigation: false,
  },
  products: {
    catalog: {
      title: "Terra-Katalog",
      file: `${MEDIA_PATH}/productcatalog/wortmann-katalog.pdf`,
      vaildUntil: "19.04.24"
    }
  },
  contactForm: {
    name: {
      title: "Name",
      errors: {
        min: "Text zu kurz!",
        max: "Text zu lang!",
        required: "Feld ist erforderlich!",
      },
    },
    email: {
      title: "Email",
      errors: {
        email: "Falsche E-Mail Adresse!",
        required: "Feld ist erforderlich!",
      },
    },
    message: {
      title: "Nachricht",
      errors: {
        min: "Text zu kurz!",
        max: "Text zu lang!",
        required: "Feld ist erforderlich!",
      },
    },
    submit: {
      title: "Absenden",
    },
    globalMessage: "Email erfolgreich versendet!"
  },
  company: {
    claim:
      "Mein Name ist Jörg Kölle. Ich bin Fachinformatiker für Systemintegrationen mit langjähriger Erfahrung im Bereich PC- und Kundenbetreuung. Im Jahr 2008 gründete ich Kölle IT- Systeme als Nebengewerbe, um mein Know-how und meine Expertise an Sie weiterzugeben.",
    services: [
      {
        type: "sell",
        description: "Verkauf von PC's, Notebooks, Server, Netzwerkausrüstung, und sonstige IT-Artikel",
      },
      {
        type: "repair",
        description: "Reparaturen und Überpfüfung von Hard- und Software",
      },
      {
        type: "data-recovery",
        description: "Datenwiederherstellung bei Verlust",
      },
      {
        type: "network",
        description: "Netzwerktechnik",
      },
      {
        type: "data-security",
        description: "Datensicherungssysteme (Raid, Backup)",
      },
      {
        type: "web",
        description: "Erstellung von Webseiten",
      },
    ],
  },
  references: [
    {
      title: "Jagd & Natur",
      description:
        "Jagd wie wir sie lehren, ist die Verbindung zur Natur und den Wildtieren und darf nicht zum Hobbysport verkommen! Ethik und Moral sind die prägenden Attribute für unsere Anwärter, in unser von Hektik und Stress geplagten Umwelt. Unsere Schule besteht nunmehr seit über 15 Jahren und erst viel später wurde der Markt regelrecht überschwemmt mit Jagdschulen. Bei uns wird auch nicht auf die Uhr geschaut, wir bemühen uns um jeden Einzelnen von Anfang bis Ende. Unzählige Referenzen können das belegen. Verlassen Sie sich wie viele Hunderte vor Ihnen auf uns, dem jagdlichen Profiteam vom Jagd- u. Naturschulzentrum Wiesensteig. ",
      imageUrl: `${MEDIA_PATH}/images/jagd-und-natur.jpg`,
      altText: "Jagd und Natur - Schulzentrum Wiesensteig"
    },
    {
      title: "Foissner Elektrotechnik",
      description:
        "Die Firma Foissner Elektrotechnik GmbH beschäftigt zurzeit 4 Mitarbeiter und versteht sich als Dienstleistendes Elektrounternehmen das die gesamte Palette der elektrotechnischen Gebäudetechnik serviceorientiert abdeckt. Von der einfachen Steckdose, über die intelligente Haustechnik bis hin zur strukturierten Verkabelung und Automatisierungstechnik bieten wir jedem Bauherren bzw. Unternehmen die zu Ihm passende Lösung. Topgeschultes Personal, Flexibilität am Arbeitsplatz und modernste Werkzeuge bieten stets eine wirtschaftliche und professionelle Lösung.",
      imageUrl: `${MEDIA_PATH}/images/foissner-elektrotechnik.jpg`,
      altText: "Foissner Elektrotechnik"
    },
    {
      title: "Braun Schmierstoffe",
      description:
        "Braun ist Ihr kompetenter Partner im Mineralölbereich im Grossraum Stuttgart und Ulm. Seit über 20 Jahren beliefern wir die Industrie schnell und zuverlässig aus unserer grossen Produktpalette. Dazu gehören vorallem Schmierstoffe und Heizöl.",
      imageUrl: `${MEDIA_PATH}/images/braun-schmierstoffe.jpg`,
      altText: "Braun Schmierstoffe"
    },
    {
      title: "Getraenke Beer",
      description:
        'Rund um Wiesensteig hat der "Getränke Beer" seit nunmehr 40 Jahre einen guten Ruf, ist bekannt für seine große Auswahl, seinen Abholmarkt und den Vor- Ort Service und das familiäre, freundliche Ambiente. Dieser Erfolg wäre natürlich nicht ohne all die vielen Kunden, die dem Betrieb schon seit Jahren die Treue halten.',
      imageUrl: `${MEDIA_PATH}/images/getraenke-beer.jpg`,
      altText: "Getraenke Beer Wiesensteig"
    },
    {
      title: "Wiedmann Group",
      description: "",
      imageUrl: `${MEDIA_PATH}/images/wiedmann-group.jpg`,
      altText: "Wiedmann Group"
    },
  ],
  partner: [
    {
      title: "Wortmann AG",
      description:
        "Die Wortmann AG gehört mit ca. 7500 Wiederverkäufern, 350 Mitarbeiter und einem Umsatz von ca. 350 Mio.€ in 2010, zu den bedeutesten unabhängigen Unternehmen der IT-Branche. Als erfolgreiches IT- Unternehmen ist es der WORTMANN AG möglich, auf eine inzwischen mehr als 25-jährige, ungebrochen erfolgreiche Vergangenheit zurückzublicken. Die 1986 von Dipl. Kaufmann Siegbert Wortmann, seiner Ehefrau Gabriele und Herrn Thomas Knicker gegründete Wortmann Terra Implex Computer- und Dienstverarbeitungs GmbH ist im Jahre 1998 in der Wortmann AG aufgegangen. Kern der Unternehmensstrategie ist die eindeutige Ausrichtung auf Produktion, Distribution und die Bereitstellung von Leistungen im Bereich von Informationstechnologien für den indirekten Vertrieb.",
      imageUrl: `${MEDIA_PATH}/svgs/wortmann-logo.svg`,
    },
    {
      title: "Eset",
      description:
        "Für Anwender im Privat- und Geschäftsbereich bietet ESET verschiedene Sicherheitspakete an, die je nach Unternehmensgröße mehrere Einzelanwendungen verknüpfen. Sie umfassen Lösungen für Mailserver, Netzwerk-Gateways und Fileserver unterschiedlicher Serverbetriebssysteme sowie E-Mail-Serverplattformen. Kölle IT- Systeme setzt bereits mehrere Jahre mit Erfolg auf diese Software und machte damit die IT- Welt ein kleinwenig sicherer.",
      imageUrl: `${MEDIA_PATH}/svgs/eset-logo.svg`,
    },
    {
      title: "Haeussler",
      description:
        "Der Name Häußler steht seit vielen Jahren für Qualität im Bereich der Branchensoftware für das Baunebengewerbe. Häußler IT ist aber nicht nur Softwarehersteller, sondern bietet auch Dienstleistung in den Bereichen Systeminstallation und Wartung. Über unsere Partner erhalten Sie Spitzenhardware zum fairen Preis.",
      imageUrl: `${MEDIA_PATH}/svgs/haeussler-logo.svg`,
    },
    {
      title: "KOSATEC Computer GmbH",
      description:
        "Die Fa. KOSATEC Computer GmbH ist seit über 29 Jahren am Markt. Neben dem Hauptsitz in Braunschweig verfügt die Firma deutschlandweit über 8 weitere Standorte. Als Vollsortimenter in der IT Distribution, also im Großhandel, beliefern sie täglich gewerbliche Kunden europaweit mit IT Komponenten.",
      imageUrl: `${MEDIA_PATH}/svgs/kosatec-logo.svg`,
    },
  ],
  dataprivacy: {
    headline: FOOTER_NAV_ITEM.DATA_PRIVACY,
  },
  imprint: {
    headline: FOOTER_NAV_ITEM.IMPRINT,
  },
  socialMedia: {
    facebook: "https://www.facebook.com/KoelleIT/",
    linkedIn: "https://www.linkedin.com/company/36452130/",
    google: "https://www.google.com/search?client=firefox-b-d&q=k%C3%B6lle+it#lrd=0x47999eb203319ae1:0xf0ae800160930922,1"
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case Actions.SET_FOOTER_CONTENT:
      return {
        ...state,
        currentFooterContent: action.payload,
      };
    case Actions.SHOW_MOBILE_NAVIGATION:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          showMobileNavigation: action.payload,
        },
      };
    case Actions.ADAPT_NAV_LINKS:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          navLinks: action.payload,
        },
      };
    default:
      return { ...state };
  }
};
